/**
 * Created by Zaid on 2015-06-24.
 */

(function() {

    //submit form via ajax for should have data-ajax attr = 'data-ajax'
    // data-success-message-heading =''  (optional)
    // data-success-message =''  (optional)

    $('form[data-ajax]').on('submit', function(e){

        var form = $(this);
        var method = form.find('input[name="_method"]').val() || 'POST';
        var url = form.prop('action');

        var submit = form.find(':submit');
        $(submit).hide('fast');

        var loading =  $("#load-spinner");
        $(loading).show();

        $.ajax({
            type: method,
            url : url,
            data : form.serialize(),
            success: function(){

                var messageHeading = form.data('success-message-heading');
                var message = form.data('success-message');

                if (message){
                    swal(messageHeading, message , "success")
                }else{
                    swal("Good job!", "Form Submitted.", "success")
                }

                $(form)[0].reset();
                $(submit).show('fast');
                $(loading).hide();

            },
            error: function() {

                swal("Oops!", "Something Went wrong!", "error")

                $(submit).show('fast');
                $(loading).hide();
            }

        })

        e.preventDefault();




    });



    //confirm button should have attr data-confirm = 'confirmation message here'

    $('input[data-confirm], button[data-confirm]').on('click', function(e){

        var input = $(this);
        var form = input.closest('form');

        if (! confirm(input.data('confirm'))){
            e.preventDefault();
        }


    });



})();


